// 
// _carousel.scss
// 


.carousel-indicators-rounded {
    button{
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
    }
}