// 
// Chat.scss
//

// chat left sidebar

.chat-leftsidebar{
  @media (min-width: 992px) {
    min-width: 260px;
  }

  @media (min-width: 1200px) {
    min-width: 380px;
  }

  .chat-leftsidebar-nav{
    .nav{
      .nav-link{
        &.active{
          background-color: $card-bg;
          color: $primary;
        }
      }
    }
  }
}

// search-box

.search-box{
  .form-control{
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 0;
      height: 100%;
  }
}


.chat-noti-dropdown{
  &.active{
    &:before{
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $danger;
      border-radius: 50%;
      right: 0;
    }
  }

  .btn{
    padding: 6px;
    box-shadow: none;
    font-size: 20px;
  }
}

.chat-message-list{
  height: calc(100vh - 346px);
  @media (min-width: 992px){
    height: calc(100vh - 406px);
  }
}



.chat-list{
  margin: 0;
  li{
    &.active{
      a{
        background-color: rgba($primary, 0.075);
        border-color: transparent;
      }
    }
    a{
      display: block;
      padding: 14px 16px;
      color: $text-muted;
      transition: all 0.4s;
      border-top: 1px solid $border-color;
      position: relative;
      &:hover{
        background-color: rgba($primary, 0.075);
        border-color: transparent;
      }
    }

    .user-img{
      position: relative;

      .user-status{
        width: 10px;
        height: 10px;
        background-color: $gray-500;
        border-radius: 50%;
        border: 2px solid $card-bg;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &.online{
        .user-status{
          background-color: $success;
        }
      }

      &.away{
        .user-status{
          background-color: $warning;
        }
      }
    }

    &.unread{
      a{
        font-weight: $font-weight-semibold;
        color: $dark;
      }
    }

    .unread-message {
      position: absolute;
      display: inline-block;
      right: 16px;
      top: 33px;
    }
  }
}

.contact-list{
  font-size: 12px;
  text-transform: uppercase;
  color: $text-muted;
  font-weight: $font-weight-semibold;
  margin-bottom: 7px;
}

// user chat nav

.user-chat-nav{
  .dropdown{
    .nav-btn{
      height: 36px;
      width: 36px;
      line-height: 36px;
      box-shadow: none;
      padding: 0;
      font-size: 18px;
    }
  }
}

// chat conversation

.chat-conversation{
  height: calc(100vh - 300px);
  @media (min-width: 992px){
    height: calc(100vh - 360px);
  }
  li{
    clear: both;
  }

  .chat-day-title{
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    border: none;

    .title{
      background-color: $card-bg;
      position: relative;
      z-index: 1;
      padding: 6px 24px;
    }

    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }
    .badge{
      font-size: 12px;
    }
  }

  .conversation-list{
    margin-bottom: 24px;
    position: relative;

    .ctext-wrap {
      display: flex;
      margin-bottom: 10px;
    }

    .ctext-wrap-content {
      padding: 12px 20px;
      background-color: $primary;
      border-radius: 0px 8px 8px 8px;
      color: $white;
      position: relative;
      margin-left: 16px;

      &:before {
          content: "";
          position: absolute;
          border: 5px solid transparent;
          border-right-color: $primary;
          border-top-color: $primary;
          left: -10px;
          top: 0;
      }
    }

  .conversation-name {
    margin-bottom: 4px;
    display: flex;
    font-size: 13px;
      .time{
        font-size: 12px;
        color: rgba($white, 0.5); 
        margin-left: 8px;
        font-weight: $font-weight-normal;
      }

      .user-name{
        color: rgba($white, 0.5); 
      }
  }


  .dropdown {
      .dropdown-toggle {
          font-size: 18px;
          padding: 4px;
          color: $gray-600;

          @media (max-width: 575.98px) {
              display: none;
          }
      }

  }


  .message-img {
    border-radius: .2rem;
    position: relative;

      .message-img-list {
          position: relative;
      }

      img {
          max-width: 140px;
      }
    }
  }

  .right {
    .conversation-list {
        float: right;
        text-align: right;

        .ctext-wrap {

            .ctext-wrap-content {
                order: 2;
                background-color: rgba($light, 0.5);
                text-align: right;
                border-radius: 8px 0px 8px 8px;
                margin-left: 0;
                margin-right: 16px;
                color: $dark;

                &:before {
                    border: 5px solid transparent;
                    border-top-color: rgba($light, 0.5);
                    border-left-color: rgba($light, 0.5);
                    left: auto;
                    right: -10px;
                }
            }

            .conversation-name {
              justify-content: flex-end;
              .user-name, .time{
                color: $text-muted; 
              }
              .time{
                order: 1;
                margin-left: 0;
                margin-right: 8px;
              }

              .user-name{
                  order: 2;
                }
            }
        }

        .dropdown {
            order: 1;
        }
      }
  }
}

.chat-send{
  @media (max-width: 575.98px) {
    min-width: auto;
  }
 }

body[data-layout="horizontal"]{
  .chat-message-list{
    height: calc(100vh - 346px);
    @media (min-width: 992px){
      height: calc(100vh - 476px);
    }
  }

  .chat-conversation{
    height: calc(100vh - 300px);
    @media (min-width: 992px){
      height: calc(100vh - 420px);
    }
  }
}

body[data-layout-mode="dark"] {

  .chat-leftsidebar{
    .chat-leftsidebar-nav {
      .nav .nav-link{
        &.active{
          background-color: $gray-dark-200;
        }
      }
    }
  }

  .chat-list{
    li{
      a{
        color: $gray-dark-400;
        border-color: $gray-dark-300;
      }

      .user-img{
        .user-status{
          border-color: $gray-dark-300;
        }
      }

      &.unread{
        a{
          color: $gray-dark-500;
        }
      }
    }
  }

  .chat-conversation{
    .chat-day-title {
      .title{
        background-color: $gray-dark-200;
      }

      &::before{
        background-color: $gray-dark-300;
      }
    }

    .right{
      .conversation-list {
        .ctext-wrap {
          .ctext-wrap-content{
            background-color: rgba($gray-dark-300, 0.5);
            color: $gray-dark-500;
            &::before{
              border-top-color: rgba($gray-dark-300, 0.5);
              border-left-color: rgba($gray-dark-300, 0.5);
            }
          }

          .conversation-name{
            .time, .user-name{
              color: $gray-dark-400;
            }
          }
        }
      }
    }
  }
}
